import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const clubPageApi = createApi({
  reducerPath: "clubPageApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/apiv2/clubpage/`,
  }),
  endpoints: (builder) => ({
    getClubPageInfo: builder.mutation({
      query: (body) => ({
        url: `get`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetClubPageInfoMutation } = clubPageApi;
